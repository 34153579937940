<template>
  <div class="app-context mobile-layout">
    <div class="context mobile">
      <directive1 :directiveInfo="directiveInfo"></directive1>
      <div class="complete">
        <p>동의요청이 <b>완료</b>되었습니다.</p>
        <p>진료의뢰서 작성을 진행할 수 있습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Directive1 from "@/components/join/Directive1";

export default {
  components: {
    Directive1,
  },
  data() {
    return {
      directiveInfo: {
        title: "동의요청완료",
        isTitleNewLine: false,
        content: null,
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
        isMobile: true,
      },
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.app-context .context.mobile {
  padding: 0 !important;
  .directive {
    padding: 35px 0 0 0;
  }
  .complete {
    position: relative;
    padding: 35px 0 0 0;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #146f83;
      top: 0;
    }
    p {
      font-family: "SUIT";
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      b {
        font-weight: bold;
        color: #146f83;
      }
    }
  }
}
</style>
